import axios from 'axios';
import httpConfig from '../environments';
const createInterceptor = instance => {
    instance.interceptors.request.use(
        function (config) {
            // 在发送请求之前做些什么
            return config;
        },
        function (error) {
            // 对请求错误做些什么
            return Promise.reject(error);
        }
    );

    // 添加响应拦截器
    instance.interceptors.response.use(
        function (response) {
            // 对响应数据做点什么
            return response;
        },
        function (error) {
            // 对响应错误做点什么
            return Promise.reject(error);
        }
    );
};

const baseURL = httpConfig.url;
const httpInstance = axios.create({
    baseURL,
    headers: {
        Accept: 'application/json',
    },
});
createInterceptor(httpInstance);
export const $http = httpInstance;
